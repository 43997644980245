import { graphql } from "gatsby"
import * as React from "react"
import FindDreamJob from "../../components/careers/finddreamjob"
import JobDescription from "../../components/careers/jobdescription"
import JobOpenings from "../../components/careers/jobopenings"
import JobSearchForm from "../../components/careers/jobsearchform"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

const JobDescriptionPage = ({ data, location }) => {
  const pageTitle = "Job Opportunities"
  const pageDescription = "Look at our Job Opportunities"
  const lang = 'EN'
  const meta = []

  const breadcrumbMap = [
    { title: "Careers", url: "/careers/" },
    { title: "Location", url: "/san-francisco/" },
    { title: "Job Description", url: "#" }
  ]

  return (
    <>
      <Layout breadcrumbMap={breadcrumbMap}>
        <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
        <JobSearchForm></JobSearchForm>
        <JobDescription></JobDescription>
        <JobOpenings></JobOpenings>
        <FindDreamJob></FindDreamJob>
      </Layout>
    </>
  )
}

export default JobDescriptionPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
